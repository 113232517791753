/* Button styles */
.load-more-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.load-more-button:hover {
  background-color: #45a049;
}

/* Container for photos */
.photos-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

/* Individual photo item */
.photo-item {
  position: relative;
  cursor: pointer;
}

.photo-item img {
  width: 100%;
  display: block;
  border-radius: 5px;
}

/* Overlay for displaying details on hover */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.photo-item:hover .overlay {
  opacity: 1;
}

.overlay p {
  margin: 5px 0;
}

/* Modal styles */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal.visible {
  display: block;
}

/* Modal styles */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal.visible {
  display: block;
}

/* Modal content */
.modal-content {
  background-color: #333;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #555;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
}

.modal-image {
  width: 100%;
  height: auto;
}

/* Close button */
.close {
  color: #fff;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #ddd;
  text-decoration: none;
}
